import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { TransitionLink } from "@atoms/Link"
import { FluidImage, BackgroundFluidImage } from "@atoms/Image"
import css from "@css/projects/page/p-card.module.styl"

function CardA({data})
{
    return(
        <div className={css.container}>
            { data && data.map(({title,text,links, childDom}, i)=>{
                return(
                    <div className={css.item} key={i}>
                        <div className={css.title} dangerouslySetInnerHTML={{ __html: title }}/>
                        <div className={css.text} dangerouslySetInnerHTML={{__html: text }}/>
                        { childDom && childDom }
                        <div className={css.links}>
                            { links.map((link,i)=>{
                                return(
                                    <TransitionLink key={i} to={link[1]} blank={link[2]}>{link[0]}</TransitionLink>
                                )
                            })}
                        </div>
                    </div>
                )
            })}
        </div>
    )
}

function CardB({data})
{
    console.log('csr list',data)
    return(
        <div className={css.container}>
            { data && data.edges.map(( {node}, i)=>{
                const { title, content, relate } = node
                return(
                    <div className={css.item} key={i}>
                        <div className={css.title} dangerouslySetInnerHTML={{ __html: title }}/>
                        <div className={css.text} dangerouslySetInnerHTML={{__html: content }}/>
                        <div className={css.links}>
                            { relate && relate.map(( {title, slug, date},i)=>{
                                return(
                                    <TransitionLink key={i} to={`/news/${slug}`}>{`【${date}】${title}`}</TransitionLink>
                                )
                            })}
                        </div>
                    </div>
                )
            })}
        </div>
    )
}
export { CardA, CardB }
