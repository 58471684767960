import React from "react"
import { graphql, useStaticQuery } from "gatsby"
// import { useObserver } from "mobx-react-lite"
// import { useStore } from '@hooks/useStore'
import { FluidImage } from '@atoms/Image'
import Seo from "@atoms/Seo"
import Hero from "@blocks/page/Hero"
import { BreadcrumbList } from "@projects/BreadcrumbList"
import { Section, Title, Paragraph, Column, Figure} from "@blocks/page/Section"
import { CardB } from "@blocks/page/Card"
import { ContentFooter } from "@projects/ContentFooter"
import css from "@css/projects/page/p-content.module.styl"

export default function CSR活動() {
	const q = useStaticQuery(graphql`
        query {
            main01 : file(relativePath: { eq: "pageVision__img__hero@2x.jpg" }) { childImageSharp {
                    fluid(maxWidth: 1440) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
            } }
			message : file(relativePath: { eq: "pageVision__img__message@2x.jpg" }) { childImageSharp {
                    fluid(maxWidth: 640) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
            } }
			safetybus01 : file(relativePath: { eq: "csrSafetybus__img__2023-1.jpg" }) { childImageSharp {
				fluid(maxWidth: 320) {
					...GatsbyImageSharpFluid_withWebp
				}
			}}
			safetybus02 : file(relativePath: { eq: "csrSafetybus__img__2023-2.jpg" }) { childImageSharp {
				fluid(maxWidth: 320) {
					...GatsbyImageSharpFluid_withWebp
				}
			}}
			syukusya : file(relativePath: { eq: "pageInfo__img__03@2x.jpg" }) { childImageSharp {
				fluid(maxWidth: 1440) { ...GatsbyImageSharpFluid_withWebp }
			} }
			csrActivityList :allMicrocmsCsr(
				filter: { type: {eq: "activity" }}
			){
				edges {
				  	node {
						type
						csrId
						content
						title
						relate {
							title
							slug
							date(formatString: "YYYY/MM/DD")
						}
				  	}
				}
			}
			csrWelfareList :allMicrocmsCsr(
				filter: { type: {eq: "welfare" }}
			){
				edges {
				  	node {
						type
						csrId
						content
						title
						relate {
							title
							slug
							date(formatString: "YYYY/MM/DD")
						}
				  	}
				}
			}
        }
    `)
	
	return (
		<>
			<Seo subtitle={'CSR活動'}/>
			<Hero
				image={q.main01}
				label='取り組み'
				title1='CSR活動'
				title2=''
				links={[
					{
						label: 'さまざまな活動',
						scroll: '#activity'
					}, {
						label: '福利厚生',
						scroll: '#welfare'
					}
				]}
			/>
			<div className={css.container}>
				<div className={css.inner}>
					<BreadcrumbList title="取り組み" />
					<Section id="activity">
						<Title title1='Activity' title2='さまざまな活動' />
						<CardB data={q.csrActivityList}/>
					</Section>
					<Section id="welfare">
						<Title title1='Welfare' title2='福利厚生' />
						<CardB data={q.csrWelfareList} />
					</Section>
					<Section>
						<Figure caption="※大泉建設 千歳平宿">
							<FluidImage data={q.syukusya}/>
						</Figure>
					</Section>
				</div>
			</div>
			<ContentFooter
				banner={{
					img: q.main01,
					label: 'Recruit',
					title: '採用情報',
					to: '/recruit/',
				}}
			/>
		</>
	)
}
